export default {
  L_HEIGHT: 1300,
  DESKTOP: 1366,
  L_TABLET: 1025,
  TABLET: 769,
  PHONE: 550,
  M_PHONE: 440,
  S_PHONE: 360
};


