import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { blue, orange, grey, lightgrey } from "../../constants/theme"

// index container
export const Container = styled.div`
width: 50%;
// background: lightblue;
height: auto;
position: relative;
bottom: 0;
padding: 1rem;
display: flex;
margin-top: 15rem;
flex-direction: column;
font-size: 1.25rem;
min-width: 700px;
z-index: 1000;

@media screen and (min-height: 1150px) {
  padding-top: 20rem;
  
}



${MEDIA.L_TABLET`
// margin-top: 20rem;
  `};

${MEDIA.TABLET`

  `};


  ${MEDIA.PHONE`
min-width: 0px;
width: 100%;

  `};

  ${MEDIA.M_PHONE`
margin-top: 12.5rem;
  `};

  ${MEDIA.S_PHONE`

  `};

`

export const FooterStatement = styled.p`
height: 50%;
color: ${grey};
font-weight: 500;
line-height: 1.75rem;
margin-bottom: 1rem;
padding-top: 2.5rem;
font-size: 1.15rem;
// background: green;
width: 100%;
max-width: 500px;
margin-left: 1rem;

span {
    // margin-left: 2.5rem;
}


${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`

  `};


  ${MEDIA.PHONE`
  width: 95%;
font-size: 1rem;
line-height: 1.1rem;
  `};

  ${MEDIA.M_PHONE`
width: 85%;
margin-left: 1.5rem;
  `};

  ${MEDIA.S_PHONE`

  `};
`

export const Line = styled.hr`
width: ${props => (props.number === "1" ? '85rem' : props.number === "2" ? '75rem' :
    props.number === "3" ? `60rem` : '0')};
height: 2px;
position: absolute;
left: 0;

border: none;
margin-top: 1rem;
// margin-top: ${props => (props.number === "3" ? "1.5rem" : "")};
// margin-left: ${props => (props.number === "3" ? "-1rem" : "")};

background: ${props => (props.number === "1" ? `${orange}` :
    props.number === "2" ? `${blue}` : props.number === "3" ? `${lightgrey}` : 'black')};



${MEDIA.L_TABLET`

`};

${MEDIA.TABLET`

`};


${MEDIA.PHONE`
width: ${props => (props.number === "1" ? '85rem' : props.number === "2" ? '45rem' :
      props.number === "3" ? `35rem` : '0')};
      height: 1px;

`};

${MEDIA.M_PHONE`
width: ${props => (props.number === "1" ? '85rem' : props.number === "2" ? '20rem' :
      props.number === "3" ? `20rem` : '0')};
      height: 1px;

`};

${MEDIA.S_PHONE`

`};
`

export const FooterIcons = styled.ul`
top: 50%;
width: 100%;
// background: pink;
margin-top: 1rem;
// margin-left: 15rem;
margin-bottom: 1rem;
min-width: 1000px;

> * {
    margin: 1.5rem;
}
br {
    display: none;
}

${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`

  `};


  ${MEDIA.PHONE`
// margin-left: 12.5%;

> * {
    margin: 1.4rem;
}


  `};

  ${MEDIA.M_PHONE`
// margin-left: 9rem;

br {
    display: block;
}

> * {
    // margin: 1.5rem;
}
  `};

  ${MEDIA.S_PHONE`
  > * {
    margin: 0.75rem;
}
  `};
`

export const FooterPageLinks = styled.ul`
font-size: 1rem;
// background: orange;
list-style: none;
// margin-left: 16rem;
margin-top: 1.5rem;
br {
    display: none;
}
li {
    color: blue;
    display: inline;
    margin-left: 1rem;
    margin-right: 1rem;
    width: 50%;
    z-index: 10;
    
    &:first-child {
      margin-left: 0;
    }
    
    a {
       
        text-decoration: none;
        color: ${grey};
        // position: absolute;
        &:hover {
            cursor: pointer;
        }
    }
}


${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`

  `};


  ${MEDIA.PHONE`
// margin-left: 7rem;
font-size: .9rem;
li {
margin-left: 0rem;
margin-right: 0.5rem;
}
  `};

  ${MEDIA.M_PHONE`
margin-left: 0rem;
font-size: 0.9rem;
span {
    margin-left: 1rem;
}
li {
  
  margin-left: .5rem;
  
}
br {
    display: block;
}
  `};

  ${MEDIA.S_PHONE`

  `};

`

export const Copyright = styled.p`
// background: blue;
width: 100%;
min-width: 1000px;
margin-top: 2rem;
margin-left: 1rem;
color: ${grey};
font-size: 1.25rem;


${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`

  `};


  ${MEDIA.PHONE`
margin-left: 0rem;
margin-top: 3rem;
  `};

  ${MEDIA.M_PHONE`
margin-left: 1.5rem;
margin-top: 2rem;
font-size: .75rem;
  `};

  ${MEDIA.S_PHONE`

  `};
`