import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';


// icons

import { Linkedin, Instagram, Facebook, Twitter, Youtube } from '@styled-icons/feather'
import { Envelope } from '@styled-icons/evil'
import { Clubhouse, Tiktok } from '@styled-icons/simple-icons'
// import { TikTok } from '@styled-icons/simple-icons'
// @styled-icons/simple-icons/Tiktok
// mobile
import {
  Linkedin as LinkedinMobile, InstagramAlt as InstagramMobile,
  FacebookCircle as FacebookMobile, Twitter as TwitterMobile,
  Youtube as YoutubeMobile
} from '@styled-icons/boxicons-logos'
import { Discord } from "@styled-icons/remix-line/Discord"
import { Envelope as EnvelopeMobile } from '@styled-icons/fa-solid'



export const DiscordIcon = styled(Discord)`
color: ${props => (props.color ? props.color : "white")};
z-index: 103;
height:  ${props => (props.size ? props.size : "5rem")};
width:  auto;
filter: opacity(75%);
${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`
height: 3.5rem;

  `};


  ${MEDIA.PHONE`
  height: 3rem;
  width: auto;
  `};

  ${MEDIA.M_PHONE`
  height: 2.25rem;
  width: auto;
  `};

  ${MEDIA.S_PHONE`
  height: 2rem;
  width: auto;
  `};

`


export const LinkedinIcon = styled(Linkedin)`
color: ${props => (props.color ? props.color : "white")};
z-index: 103;
height:  ${props => (props.size ? props.size : "4.5rem")};
width:  auto;
${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`
height: 3.5rem;

  `};


  ${MEDIA.PHONE`
  height: 3rem;
  width: auto;
  `};

  ${MEDIA.M_PHONE`
  height: ${props => props.type === "shareTab" ? "2rem" : "2.25rem"};
  
  width: auto;
  `};

  ${MEDIA.S_PHONE`
  height: 2rem;
  width: auto;
  `};
`

export const TikTokIcon = styled(Tiktok)`
color: ${props => (props.color ? props.color : "white")};
z-index: 103;
height:  ${props => (props.size ? props.size : "5rem")};
width:  auto;
${MEDIA.L_TABLET` 

  `};

${MEDIA.TABLET`
height: 3.5rem;

  `};


  ${MEDIA.PHONE`
  height: 3rem;
  width: auto;
  `};

  ${MEDIA.M_PHONE`
  height: ${props => props.type === "shareTab" ? "2rem" : "2.25rem"};
  
  width: auto;
  `};

  ${MEDIA.S_PHONE`
  height: 2rem;
  width: auto;
  `};
`


export const FacebookIcon = styled(Facebook)`
color: ${props => (props.color ? props.color : "white")};
z-index: 103;
height:  ${props => (props.size ? props.size : "5rem")};
width:  auto;

${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`
height: 3.5rem;

  `};


  ${MEDIA.PHONE`
  height: 3rem;
  width: auto;
  `};

  ${MEDIA.M_PHONE`
  height: ${props => props.type === "shareTab" ? "2rem" : "2.25rem"};
  width: auto;
  `};

  ${MEDIA.S_PHONE`
  height: 2rem;
  width: auto;
  `};
`


export const InstagramIcon = styled(Instagram)`
color: ${props => (props.color ? props.color : "white")};
z-index: 103;
height:  ${props => (props.size ? props.size : "5rem")};
width:  auto;
${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`
height: 3.5rem;

  `};


  ${MEDIA.PHONE`
  height: 3rem;
  width: auto;
  `};

  ${MEDIA.M_PHONE`
  height: 2.25rem;
  width: auto;
  `};

  ${MEDIA.S_PHONE`
  height: 2rem;
  width: auto;
  `};
`


export const TwitterIcon = styled(Twitter)`
color: ${props => (props.color ? props.color : "white")};
z-index: 103;
height:  ${props => (props.size ? props.size : "5rem")};
width:  auto;
${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`
height: 3.5rem;

  `};


  ${MEDIA.PHONE`
  height: 3rem;
  width: auto;
  `};

  ${MEDIA.M_PHONE`
  height: ${props => (props.type === "shareTab" ? "1.9rem" : "2.25rem")};
  width: auto;
  `};

  ${MEDIA.S_PHONE`
  height: 2rem;
  width: auto;
  `};
`


export const MailIcon = styled(Envelope)`
color: ${props => (props.color ? props.color : "white")};
z-index: 103;
height:  ${props => (props.size ? props.size : "7rem")};
width:  auto;
${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`
height: 4rem;

  `};


  ${MEDIA.PHONE`
  height: 3rem;
  width: auto;
  `};

  ${MEDIA.M_PHONE`
  height: ${props => props.type === "shareTab" ? "2.25rem" : "2.5rem"};
  width: auto;
  `};

  ${MEDIA.S_PHONE`
  height: 2rem;
  width: auto;
  `};
`

export const YoutubeIcon = styled(Youtube)`
color: ${props => (props.color ? props.color : "white")};
z-index: 103;
height:  ${props => (props.size ? props.size : "6rem")};
width:  auto;
${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`
height: 3.5rem;

  `};


  ${MEDIA.PHONE`
  height: 3rem;
  width: auto;
  `};

  ${MEDIA.M_PHONE`
  height: 2.25rem;
  width: auto;
  `};

  ${MEDIA.S_PHONE`
  height: 2rem;
  width: auto;
  `};
`

export const ClubhouseIcon = styled(Clubhouse)`
color: ${props => (props.color ? props.color : "white")};
z-index: 103;
height:  ${props => (props.size ? props.size : "5rem")};
width:  auto;
${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`
height: 3.5rem;

  `};


  ${MEDIA.PHONE`
  height: 3rem;
  width: auto;
  `};

  ${MEDIA.M_PHONE`
  height: 2.25rem;
  width: auto;
  `};

  ${MEDIA.S_PHONE`
  height: 2rem;
  width: auto;
  `};
`

// mobile icons used for mobile nav and about
export const LinkedinIconMobile = styled(LinkedinMobile)`
color: ${props => (props.color ? props.color : "white")};
z-index: 103;
height:  ${props => (props.size ? props.size : "5rem")};
width:  auto;
${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`
height: 3.5rem;

  `};


  ${MEDIA.PHONE`
  height: 3rem;
  width: auto;
  `};

  ${MEDIA.M_PHONE`
  height: 2.25rem;
  width: auto;
  `};

  ${MEDIA.S_PHONE`
  height: 2rem;
  width: auto;
  `};
`
export const FacebookIconMobile = styled(FacebookMobile)`
color: ${props => (props.color ? props.color : "white")};
z-index: 103;
height:  ${props => (props.size ? props.size : "5rem")};
width:  auto;
${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`
height: 3.5rem;

  `};


  ${MEDIA.PHONE`
  height: 3rem;
  width: auto;
  `};

  ${MEDIA.M_PHONE`
  height: 2.25rem;
  width: auto;
  `};

  ${MEDIA.S_PHONE`
  height: 2rem;
  width: auto;
  `};
`
export const TwitterIconMobile = styled(TwitterMobile)`
color: ${props => (props.color ? props.color : "white")};
z-index: 103;
height:  ${props => (props.size ? props.size : "5rem")};
width:  auto;
${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`
height: 3.5rem;

  `};


  ${MEDIA.PHONE`
  height: 3rem;
  width: auto;
  `};

  ${MEDIA.M_PHONE`
  height: 2.25rem;
  width: auto;
  `};

  ${MEDIA.S_PHONE`
  height: 2rem;
  width: auto;
  `};
`
export const YoutubeIconMobile = styled(YoutubeMobile)`
color: ${props => (props.color ? props.color : "white")};
z-index: 103;
height:  ${props => (props.size ? props.size : "5rem")};
width:  auto;
${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`
height: 3.5rem;

  `};


  ${MEDIA.PHONE`
  height: 3rem;
  width: auto;
  `};

  ${MEDIA.M_PHONE`
  height: 2.25rem;
  width: auto;
  `};

  ${MEDIA.S_PHONE`
  height: 2rem;
  width: auto;
  `};
`
export const InstagramIconMobile = styled(InstagramMobile)`
color: ${props => (props.color ? props.color : "white")};
z-index: 103;
height:  ${props => (props.size ? props.size : "5rem")};
width:  auto;
${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`
height: 3.5rem;

  `};


  ${MEDIA.PHONE`
  height: 3rem;
  width: auto;
  `};

  ${MEDIA.M_PHONE`
  height: 2.25rem;
  width: auto;
  `};

  ${MEDIA.S_PHONE`
  height: 2rem;
  width: auto;
  `};
`
export const MailIconMobile = styled(EnvelopeMobile)`
color: ${props => (props.color ? props.color : "white")};
z-index: 103;
height:  ${props => (props.size ? props.size : "7rem")};
width:  auto;


${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`
height: 3.5rem;

  `};


  ${MEDIA.PHONE`
  height: 3rem;
  width: auto;
  `};

  ${MEDIA.M_PHONE`
  height: 2.25rem;
  width: auto;
  `};

  ${MEDIA.S_PHONE`
  height: 2rem;
  width: auto;
  `};
`