import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { Container } from './nav.css';
import Menu from '../menu/menu'


const Nav = () => {

  return (
    <Container>

      <Menu />

      <ul>
        <li>
          <a href="https://www.ntheos.com">NTHEOS</a>
        </li>

        <li>
          <Link to='/written'>WRITTEN</Link>
        </li>
        <li>
          <Link to='/audio'>AUDIO</Link>
        </li>
        <li>
          <Link to="/video">VIDEO</Link>
        </li>
        <li>
          <a href="http://eepurl.com/hJcXhL">NEWSLETTER</a>
        </li>
        {/* <li>
          <Link to="/contact">CONTACT</Link>
        </li> */}
      </ul>



    </Container>
  )
};

export default Nav;
