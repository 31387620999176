import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { blue, orange, grey, } from "../../constants/theme"

export const Text = styled.span`
  display: flex;
  color: ${orange};
  position: relative;
  letter-spacing: .2rem;
  right: 4rem;
  line-height: 2;
  margin-right: 2rem;
  text-transform: uppercase;
  font-weight: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '400';
      default:
        return '700';
    }
  }};
  font-size: ${({ size }) => () => {
    switch (size) {
      case 'large':
        return '2.2rem';
      default:
        return '3rem';
    }
  }};
  img {
    margin-top: 0;
    padding-top: 0;
    margin-top: -1rem;
  }
   
  ${MEDIA.TABLET`
  right: 2rem;
  font-size:2.6rem;
  `};

  ${MEDIA.PHONE`
    right: 1rem;
    font-size: 2.2rem;

  `};
  ${MEDIA.M_PHONE`
right: .5rem;
  `};

  ${MEDIA.S_PHONE`

  `};
`;
