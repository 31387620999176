export const accent = '#f90000';

export const blue = 'rgb(2, 205, 252)';

export const orange = 'rgb(246, 162, 42)';

export const grey = "rgb(120,120,120)";

export const lightgrey = "rgba(0,0,0,0.2)";

export const darkMain = "rgba(0,0,0,0.85)";

export const darkFont = "rgb()"

export const darkAccent = "rgbs(0,0,0,0.20)"