import styled, { keyframes } from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import TickerImg from '../../images/ticker.png';

export const Ticker = styled.span`
overflow: hidden;
height: 100%;
left: 1rem;
position: fixed;
top: 0px;
width: 27.5px;
z-index: 2;
// background: blue;

// @media (prefers-reduced-motion: reduce) {
//             .layout__ticker span {
//               -webkit-animation: none;
//                       animation: none;
//             }
//         }

${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`

  `};


  ${MEDIA.PHONE`
top: 55px;
  `};

  ${MEDIA.M_PHONE`
  left: .33rem;

  `};

  ${MEDIA.S_PHONE`
left: 0rem;
  `};
`

const tickerAnimation = keyframes`
0% {
    transform: translateY(0);
    -webkit-transform: translateY(0);
}
100% {
    transform: translateY(-270px);
    -webkit-transform: translateY(-270px);
}
`


export const Ticking = styled.span`
animation: ${tickerAnimation} 7s infinite linear;
-webkit-animation: ${tickerAnimation} 7s infinite linear;
background-image: url(${TickerImg});
background-size: 14px auto;
background-repeat: repeat-y;
background-position: center top;
box-sizing: content-box;
display: block;
padding-bottom: 270px;
height: 100%;
width: 100%;


${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`

  `};


  ${MEDIA.PHONE`

  `};

  ${MEDIA.M_PHONE`
// // padding-bottom: 1080px;
// background-size: 7px auto;
  `};

  ${MEDIA.S_PHONE`

  `};

`




// .layout__ticker {
//     height: 100%;
//     left: 0;
//     overflow: hidden;
//     position: fixed;
//     top: 55px;
//     width: 27.5px;
//     z-index: 5; }
//     @media screen and (min-width: 550px) {
//       .layout__ticker {
//         top: 0; } }
//     .layout__ticker span {
//       -webkit-animation: ticker 7s infinite linear;
//               animation: ticker 7s infinite linear;
//       background-image: url("/static/ticker.png");
//       background-size: 7px auto;
//       background-repeat: repeat-y;
//       background-position: center top;
//       box-sizing: content-box;
//       display: block;
//       padding-bottom: 270px;
//       height: 100%;
//       width: 100%; }
//       @media (prefers-reduced-motion: reduce) {
//         .layout__ticker span {
//           -webkit-animation: none;
//                   animation: none; } }

// @-webkit-keyframes ticker {
//   from {
//     -webkit-transform: translateY(0);
//             transform: translateY(0); }
//   to {
//     -webkit-transform: translateY(-270px);
//             transform: translateY(-270px); } }

// @keyframes ticker {
//   from {
//     -webkit-transform: translateY(0);
//             transform: translateY(0); }
//   to {
//     -webkit-transform: translateY(-270px);
//             transform: translateY(-270px); } }
