import React from 'react';
import PropTypes from 'prop-types';
import {
    Container, FooterStatement, FooterIcons, FooterPageLinks, Line, Copyright
} from './footer.css';
import { Link } from 'gatsby'
import {
    LinkedinIcon,
    FacebookIcon,
    InstagramIcon,
    TwitterIcon, YoutubeIcon,
    TikTokIcon,
    MailIcon,
    LinkedinIconMobile,
    FacebookIconMobile,
    TwitterIconMobile,
    DiscordIcon
} from '../icons/icons.css'
import { blue, orange, grey, lightgrey } from "../../constants/theme"
const Footer = ({ children, type, as = '', size, tag, bg, section }) => {

    let date = new Date()

    let yr = date.getFullYear();

    return (
        <Container section={section} type={type} bg={bg} size={size} >
            {/* <Line number="1" /> */}
            <FooterStatement>

            </FooterStatement>
            {/* <Line number="2" /> */}

            <FooterIcons>

                <a href="https://tiktok.com/@ntheosmag">
                    <TikTokIcon size="3rem" color={blue} />
                </a>

                {/* <a href="https://facebook.com/ntheosmag">
                    <FacebookIcon size="3rem" color={blue} />
                </a> */}

                <a href="https://instagram.com/ntheosmag">
                    <InstagramIcon size="3rem" color={blue} />
                </a>
                <a href="https://twitter.com/ntheosmag">
                    <TwitterIcon size="3rem" color={blue} />
                </a>
                <a href="https://www.youtube.com/channel/UCTYyxYWvf8nriFtOytl7NrA">
                    <YoutubeIcon size="3rem" color={blue} />
                </a>
            </FooterIcons>
            {/* <Line number="3" /> */}

            <FooterPageLinks>
                <span />
                <li>
                    <a href="https://www.ntheos.com">NTHEOS</a>
                </li>
                <li>
                    <Link to='/written'>WRITTEN</Link>
                </li>
                <li>
                    <Link to='/audio'>AUDIO</Link>
                </li>

                <li>
                    <Link to='/video'>VIDEO</Link>
                </li>
                <li>
                    <a href="http://eepurl.com/hJcXhL">NEWSLETTER</a>
                </li>
                {/* <li>
                    <Link to="/contact">CONTACT</Link>
                </li> */}
            </FooterPageLinks>
            <Copyright>
                Ntheos, Inc. {yr} &copy;
            </Copyright>
        </Container>
    );
};

Footer.propTypes = {
    as: PropTypes.string,
    size: PropTypes.oneOf(['large']),
};

export default Footer;