import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';


export const Container = styled.div`
  display: block;
  margin-left: 5.25rem;
  background: white;
  height: auto;
  overflow: hidden;
  width: auto;
  max-width: 1450px;
  
  ${MEDIA.L_TABLET`

  `};

  ${MEDIA.TABLET`

  `};

  ${MEDIA.PHONE`

  `};

  ${MEDIA.M_PHONE`
margin-left: 3.5rem;
  `};

  ${MEDIA.S_PHONE`
margin-left: 3rem;
  `};
`;
