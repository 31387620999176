import React, { useState } from "react";
import styled from "styled-components";
import { Link } from 'gatsby';
import {
    MenuLabel, Hamburger, MenuItems, ItemContainer,
    SocialIcons, MenuLogo
} from './menu.css'
import {
    LinkedinIconMobile, ClubhouseIcon,
    InstagramIconMobile, FacebookIconMobile, TwitterIconMobile, MailIconMobile, YoutubeIconMobile
} from '../../icons/icons.css'

import NtheosLogo from '../../../images/ntheos-white.png'

export default function Menu() {

    const [open, setOpen] = useState(false);
    const handleClick = () => {
        setOpen(!open);
        document.body.classList.toggle('lock-scroll');
    };
    const untoggleLockScroll = () => {
        document.body.classList.toggle('lock-scroll');

    }

    return (
        <>
            <MenuLabel htmlFor="navi-toggle" onClick={handleClick}>
                <Hamburger open={open}>&nbsp;</Hamburger>
            </MenuLabel>

            <ItemContainer open={open}>
                <Link onClick={untoggleLockScroll} to="/">
                    <MenuLogo src={NtheosLogo} />

                </Link>

                <MenuItems open={open}>
                    {/* <li >
                        <a onClick={untoggleLockScroll} href="https://www.ntheos.com">NTH</a>
                    </li> */}

                    <li>
                        <Link onClick={untoggleLockScroll} to='/written'>WRITTEN</Link>
                    </li>
                    <li>
                        <Link onClick={untoggleLockScroll} to='/audio'>AUDIO</Link>
                    </li>
                    <li>
                        <Link onClick={untoggleLockScroll} to="/video">VIDEO</Link>
                    </li>
                    <li>
                        <Link onClick={untoggleLockScroll} to="/pillars">PILLARS</Link>
                    </li>
                    <li>
                        <a href="http://eepurl.com/hJcXhL">NEWSLETTER</a>
                    </li>
                </MenuItems>
                <SocialIcons open={open}>
                    <a onClick={untoggleLockScroll} href="http://www.youtube.com" title="Watch us!">
                        <YoutubeIconMobile />
                    </a>
                    {/* <a href="/" title="Talk to us!">
                        <ClubhouseIconMobile />
                    </a> */}
                    <a onClick={untoggleLockScroll} href="http://www.instagram.com/ntheosmag" title="Follow us!">
                        <InstagramIconMobile />
                    </a>
                    <a onClick={untoggleLockScroll} href="http://www.twitter.com/ntheosmag" title="Tweet us!">
                        <TwitterIconMobile />
                    </a>
                    {/* <a onClick={untoggleLockScroll} href="http://www.linkedin/company/ntheos" title="Connect on Linkedin!">
                        <LinkedinIconMobile />
                    </a> */}
                    {/* <a onClick={untoggleLockScroll} href="http://www.facebook.com/ntheosmag" title="Add us on Facebook!">
                        <FacebookIconMobile />
                    </a> */}
                    <a onClick={untoggleLockScroll} href="mailto:seeking@ntheos.com" title="Mail us!">
                        <MailIconMobile />
                    </a>

                </SocialIcons>
            </ItemContainer>

        </>
    );
}