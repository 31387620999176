import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';

export const Container = styled.nav`
 height: auto;
 width: auto;
 margin-left: 5rem;
 
ul {
    left: .5rem;
    font-family: 'Termina';
    position: relative;
    display: flex;
    list-style: none;
    color: red;
    align-items: left;  
    // background: lightgrey;  
    li {
      text-transform: uppercase;
      font-size: 1.5rem;
      font-weight: 700;
      & + li {
        margin-left: 2.5rem;
      }
&:hover, &:active {
 
  text-shadow: 2px 3px 6px rgba(2, 205, 252, 0.66);
  

}
    }

    
  }
   
  ${MEDIA.TABLET`
  ul {
    display: none;
  }
  margin-left: 5rem;
  `};

  ${MEDIA.PHONE`
  margin-left: 4rem;
  `};
  
  ${MEDIA.M_PHONE`

  `};

  ${MEDIA.S_PHONE`

  `};
`;
