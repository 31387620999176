import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { blue, orange, grey, } from "../../constants/theme"


export const Container = styled.header`
  display: flex;
  position: relative;
  width: 100%;

  left: 3rem;
  position: fixed;
  justify-content: space-between;
  align-items: center;
  padding-top: 4rem;
  background: white;
  padding-bottom: 2.5rem;
  max-width: 1600px;
  z-index: 10;
  a {
    color: #757575;
    transition:  0.2s ease;
    text-decoration: none;
    &:hover {
      transition: ease-in-out .2s;
      color: ${orange};      
    }
  }
  ${MEDIA.TABLET`
  left: 0;
padding-top: 3rem;
  ul {
    display: none;
  }
  `};

  ${MEDIA.PHONE`
  `};


  ${MEDIA.M_PHONE`
padding-top: 2.5rem;
padding-bottom: 1.5rem;

  `};

  ${MEDIA.S_PHONE`

  `};

`;
