import React from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Head from 'components/head';
import Header from 'components/header';
import { Container } from './layout.css.js'
import { Ticker, Ticking } from '../Ticker/ticker.css'
import { createGlobalStyle } from "styled-components"
import Seo from '../seo'
import Footer from '../footer/footer'
import "../../font/typography.css"

const GlobalStyle = createGlobalStyle`
html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline;
  // font-family: 'Termina';

}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block;
  scroll-behavior: smooth;

}


html {
  scroll-behavior: smooth;
  font-size: 62.5%;
  -webkit-tap-highlight-color: transparent; 
  // font-family: 'Termina';

}

body {
  scroll-behavior: smooth;

  font-family: -apple-system, BlinkMacSystemFont, sans-serif !important;
  line-height: 1;
  // font-size: 1.4rem;
  color: #000;
  background-color: #fff;
  // -webkit-text-size-adjust: 100%;
  // -webkit-font-smoothing: antialiased;
  // -moz-osx-font-smoothing: grayscale;
  // text-rendering: optimizeLegibility;
  // -webkit-font-feature-settings: "pnum";
  // font-feature-settings: "pnum";
  // font-variant-numeric: proportional-nums;
 overflow: auto;
}

ol, ul {
  list-style: none;
}

blockquote, q {
  quotes: none;
}

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

a {
  color: white;
}

pre {
  display: block;
  padding: 2rem;
  margin-top: 4rem;
  overflow: auto;
  font-size: 85%;
  line-height: 1.45;
  border-radius: 5px;
  color: white;
  border: 1px solid #ddd;
  font-family: 'Termina';

}

video {
  max-width: 100%;
}
.lock-scroll {
  overflow: hidden;
}

p {
  margin-bottom: 2rem;
}
`

const Layout = ({ seo, data, children }) => {

  return (
    <>
      <GlobalStyle />
      <Container>
        {/* <link rel="stylesheet" href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.2/css/all.min.css"
        integrity="sha512-HK5fgLBL+xu6dm/Ii3z4xhlSUyZgTT9tuc/hSrtw6uzJOvgRr2a9jyxxT1ely+B+xFAmJKVSTbpM/CuL7qxO8w=="
        crossrigin="anonymous" /> */}

        <Head />
        <Seo />
        {/* <Header title={data.site.siteMetadata.siteTitle} /> */}
        <GlobalStyle />

        <Header title='Ntheos' />
        <Ticker>
          <Ticking></Ticking>
        </Ticker>
        {children}
        <Footer />
      </Container>
    </>
  )
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
};

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitle
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWithQuery;
