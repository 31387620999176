import styled from 'styled-components';
import MEDIA from 'helpers/mediaTemplates';
import { blue, orange, grey, } from "../../../constants/theme"
import Img from 'gatsby-image'

export const MenuLabel = styled.label`
background-color: none;
position: fixed;
top: 2rem;
left: 1rem;
height: 3rem;
width: 3rem;
cursor: pointer;
z-index: 103;
text-align: center;
display: none;


${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`
display: block;
  `};


  ${MEDIA.PHONE`

  `};

  ${MEDIA.M_PHONE`
left: .5rem;

  `};

  ${MEDIA.S_PHONE`

  `};
`;

export const Hamburger = styled.span`
position: fixed;
z-index: 103;
background-color: ${(props) => (props.open ? "transparent" : "black")};
width: 1.5rem;
height: 2px;
display: inline-block;
margin-top: 1.3rem;
margin-left: .5rem;
z-index: 102;
transition: all 0.3s;
&::before,
&::after {
  content: "";
  background-color: ${orange};
  width: 2rem;
  height: 2px;
  display: inline-block;
  position: absolute;
  left: 0;
  transition: all 0.3s;
}
&::before {
  top: ${(props) => (props.open ? "0" : "-0.8rem")};
  transform: ${(props) => (props.open ? "rotate(135deg)" : "rotate(0)")};
}
&::after {
  top: ${(props) => (props.open ? "0" : "0.8rem")};
  transform: ${(props) => (props.open ? "rotate(-135deg)" : "rotate(0)")};
}
${MenuLabel}:hover &::before {
  top: ${(props) => (props.open ? "0" : "-1rem")};
}
${MenuLabel}:hover &::after {
  top: ${(props) => (props.open ? "0" : "1rem")};
}
display: none;


${MEDIA.L_TABLET`

  `};

${MEDIA.TABLET`
display: block;
  `};


  ${MEDIA.PHONE`

  `};

  ${MEDIA.M_PHONE`
  margin-top: 0.7rem;
  `};

  ${MEDIA.S_PHONE`

  `};
`;

export const MenuBackground = styled.div`
// background: white;
position: relative;
z-index: 100;
overflow-y: hidden;
left: 0;
top: 0;
width: 100vw;
justify-content: center;
align-items: center;
height: 100vh;
transition: ease 2s;

display: ${(props) => (props.open ? "flex" : "none")};
`


export const ItemContainer = styled.div`
display: ${(props) => (props.open ? "flex" : "none")};
z-index: 101;
position: fixed;
overflow-y: hidden;
border-top: 7.5vh solid white;
border-bottom: 7.5vh solid white;
left: 0rem;
top: 0;
width: 100vw;
height: 85vh;
background: rgba(0,0,0,0.875);
align-items: center;
justify-content: center;
transition: ease 2s;
  // transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);
  // -webkit-transition: transform 0.5s cubic-bezier(0.77,0.2,0.05,1.0);

  `


export const MenuItems = styled.ol`
display: ${(props) => (props.open ? "block" : "none")};
overflow-y: hidden;
justify-content: center;
font-family: "Termina";
font-weight: 800;
text-align: center;
li{
  right: 0;
  margin-bottom: 1.5rem;
  a {
    font-size: 4rem;
    color: white;
  }
}
`
export const Menuheader = styled.h1`

`
export const MenuLogo = styled.img`
width: 50%;
height: auto;
position: absolute;
top: 15%;
left: 25%;
`


export const SocialIcons = styled.div`
position: absolute;
// background: rgba(0,0,0,0.15);
justify-content: center;
z-index: 102;
display: ${(props) => (props.open ? "flex" : "none")};
bottom: 2rem;
align-items: center;
width: 80vw;
height: 20%;
a{
  margin: 1rem;
}
${MEDIA.PHONE`
  bottom: 10rem;
  height: 15%;
  a{
    margin: .7rem;
  }
  `};
`
